import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import SmallHeroSection from "../components/SmallHeroSection"
import DecorativeSection from "../components/DecorativeSection"
import { graphql } from "gatsby"
import DietLayout from "../components/DietLayout"

export const query = graphql`
  {
    file(name: { eq: "diet1" }) {
      base
      publicURL
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`

var planOptimal = {
  title: "Optimal",
  menu: [
    {
      name: "Śniadanie",
      hour: "7:00",
      description: "Muffiny z ricottą i gorzką czekoladą z sałatką owocową",
    },
    {
      name: "Drugie Śniadanie",
      hour: "10:00",
      description: "Zielone smoothie z melonem, jabłkiem i zieloną herbatą",
    },
    {
      name: "Obiad",
      hour: "13:00",
      description: "Wołowina po burgundzku z kluseczkami szpinakowymi",
    },
    {
      name: "Podwieczorek",
      hour: "17:00",
      description: "Pieczona gruszka z nutellą z awokado",
    },
    {
      name: "Kolacja",
      hour: "19:30",
      description:
        "Pasztet domowy z zielonej soczewicy z żurawiną, pieczywo razowe i warzywa.",
    },
  ],
}

const PlanOptimalPage = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <Seo title="Plan Optimal" />
      <SmallHeroSection />
      <DecorativeSection />
      <DietLayout diet={planOptimal} image={data.file} >
      Dieta najbardziej elastyczna, stworzona z uwzględnieniem wszystkich grup
      produktów. Jadłospis jest zbilansowany, różnorodny, smaczny i zdrowy!
      Świetna opcja dla osób zabieganych! Chcesz zrzucić zbędne kilogramy? Nie
      wiesz jak poprawić jakość codziennego żywienia? Z tą dietą odczujesz
      różnice wśród doskonałej jakości produktów i potraw!
      </DietLayout>
    </Layout>
  )
}

export default PlanOptimalPage
